import React from "react"
import { Link } from "gatsby"
import digitalimage from "../../assets/images/banners/service-images/digital.jpg"
import quality from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
import speed from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
import security from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"

import ai from '../../assets/images/banners/icon-pack/ai.png'
import big_data from '../../assets/images/banners/icon-pack/big-data.png'
import cloud from '../../assets/images/banners/icon-pack/cloud-service.png'
import microservice from '../../assets/images/banners/icon-pack/microservice.png'
import architecture from '../../assets/images/banners/icon-pack/glenn-carstens-peters-npxXWgQ33ZQ-unsplash.jpg'
import enterprise from '../../assets/images/banners/icon-pack/sean-pollock-PhYq704ffdA-unsplash.jpg'
import application from '../../assets/images/banners/icon-pack/timothy-hales-bennett-OwvRB-M3GwE-unsplash.jpg'
import agile from '../../assets/images/banners/icon-pack/daria-nepriakhina-zoCDWPuiRuA-unsplash.jpg'

const DigitalTransformation = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img digital"
          data-bg="assets/images/banners/service-banners/digital.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Digital Transformation</h1>
                  <h5
                    className="font-weight--normal text-white"
                    style={{ textTransform: "capitalize" }}
                  >
                    Improving your technological transformation process.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  <h6 className="section-sub-title mb-10">Our IT Service</h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Digital <span className="text-color-primary">Transformation</span></h2>

                  Because digital transformation is a process rather
                  than a destination. You'll need an experienced team to
                  help you take advantage of new technologies, transform
                  your business processes, and reap the benefits of
                  being a digital company. Aamanto Digital
                  Transformation practice is based on extensive
                  experience transforming legacy systems into
                  cutting-edge design improvements and delivering
                  solutions on cloud platforms. We know how to break
                  down internal dependencies, prepare ON-Premise
                  solutions, and get them ready to run on cloud
                  infrastructure as Hybrid or Native applications. We
                  have experience with business transformation,
                  accelerating custom software development operations,
                  and delivering digital services using a data-driven
                  approach. Aamanto Technologies can help you accelerate
                  your cloud migration, create new consumer experiences,
                  rethink business strategies, and increase efficiency
                  and productivity. We work quickly to meet your goals
                  in a secure, dependable, and scalable manner.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={digitalimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

           <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Accelerate your <span className="text-color-primary">Digital Transformation</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Architecture and Design</h5>
                            <div className="text">
                              We provide architecture consulting solutions for
                              enterprise-scale systems, emphasizing user
                              experience, resilience, scalability, and
                              performance. Our digital transformation services
                              help you translate your vision into reality.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">
                              Application Modernization
                            </h5>
                            <div className="text">
                              We drive a digital transformation strategy making
                              legacy systems more agile and efficient using
                              intelligent automation, industrialized assets,
                              specialized skills and global delivery
                              capabilities.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading"> Enterprise Mobility</h5>
                            <div className="text">
                              Our digital transformation consultants offer
                              comprehensive solutions that address every phase
                              of the mobility lifecycle - from device selection
                              to security while ensuring experiences.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading"> Agile Transformation</h5>
                            <div className="text">
                              We enable organizations to innovate and deliver
                              more effectively than ever-turning market
                              disruption to competitive advantage in our digital
                              transformation solutions.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="v1-dt-box-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="v1-dt-box-1-img">
                    <img src={architecture} />
                  </div>
                </div>
                <div className="col-lg-6 v1-d-f">
                  <div className="v1-dt-box-1-text">
                    <h5>Architecture and Design</h5>
                    We provide architecture consulting solutions for enterprise-scale systems, emphasizing user experience, resilience, scalability, and performance. Our digital transformation services help you translate your vision into reality.
                  </div>
                </div>
              </div>
            </div>

            <div className="v1-dt-box-1">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 v1-d-f">
                  <div className="v1-dt-box-1-text">
                    <h5>Application Modernization</h5>
                    We drive a digital transformation strategy making legacy systems more agile and efficient using intelligent automation, industrialized assets, specialized skills and global delivery capabilities.
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="v1-dt-box-1-img">
                    <img src={application} />
                  </div>
                </div>
              </div>
            </div>

            <div className="v1-dt-box-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="v1-dt-box-1-img">
                    <img src={enterprise} />
                  </div>
                </div>
                <div className="col-lg-6 v1-d-f">
                  <div className="v1-dt-box-1-text">
                    <h5>Enterprise Mobility</h5>
                    Our digital transformation consultants offer comprehensive solutions that address every phase of the mobility lifecycle - from device selection to security while ensuring experiences.
                  </div>
                </div>
              </div>
            </div>

            <div className="v1-dt-box-1">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 v1-d-f">
                  <div className="v1-dt-box-1-text">
                    <h5>Agile Transformation</h5>
                    We enable organizations to innovate and deliver more effectively than ever-turning market disruption to competitive advantage in our digital transformation solutions.
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="v1-dt-box-1-img">
                    <img src={agile} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========-->
            
              <!--===========  feature-icon-wrapper  Start =============--> */}
        <div className="feature-icon-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Digital Tansformation Services</h2>
                </div>
              </div>
            </div>

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={big_data} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Big Data Analytics</h5>
                            <div className="v1-text-2">
                              Our digital transformation solutions help you harness the untapped potential of your data through big data analytics and big data tools to drive better business decisions.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={ai} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Artificial Intelligence and ML</h5>
                            <div className="v1-text-2">
                              Our digital transformation services team helps you solve your business transformation challenges through its expertise in Neuro-Linguistic Programming (NLP), Machine Learning, smart automation and predictive analytics.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={microservice} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Microservices and Serverless</h5>
                            <div className="v1-text-2">
                              Our digital transformation consultants help you develop rapidly scalable greenfield applications and decrease operational expenses by adopting a microservices architecture coupled with serverless workloads.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={cloud} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Cloud Migration</h5>
                            <div className="v1-text-2">
                              Make your applications cloud-native and achieve accelerated timelines, enhance organizational scalability and centralize their network security with the support of our Digital Transformation Services.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-list__three">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading"> Big Data Analytics</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Our digital transformation solutions help you
                                harness the untapped potential of your data
                                through big data analytics and big data tools to
                                drive better business decisions.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-lock-alt"></i>
                              </div>
                              <h5 className="heading">
                                Artificial Intelligence and ML
                              </h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Our digital transformation services team helps
                                you solve your business transformation
                                challenges through its expertise in
                                Neuro-Linguistic Programming (NLP), Machine
                                Learning, smart automation and predictive
                                analytics.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">
                                Microservices and Serverless
                              </h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Our digital transformation consultants help you
                                develop rapidly scalable greenfield applications
                                and decrease operational expenses by adopting a
                                microservices architecture coupled with
                                serverless workloads.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">Cloud Migration</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Make your applications cloud-native and achieve
                                accelerated timelines, enhance organizational
                                scalability and centralize their network
                                security with the support of our Digital
                                Transformation Services.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
        {/* <!--===========  feature-icon-wrapper  End =============-->

            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap section-space--mb_60 text-center">
                  <h2 className="heading">Digital Transformation<span className="text-color-primary"> as a Service</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Legacy Application Modernization{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Greenfield Cloud Engineering </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Automation, DevOps Services </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Business Intelligence Solutions</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Cloud Migration and Enablement </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Increased Efficiency</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Big Data Warehousing</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Predictive Analytics</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default DigitalTransformation
