import * as React from "react"
import Layout from "../components/layout"
import DigitalTransformation from "../components/Services/DigitalTransformation"






const digital = () => {
  return (
  <Layout>
   <DigitalTransformation/>
  </Layout>
)
};
export default digital;
